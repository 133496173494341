@import 'index.scss';

.particles {
  position: absolute !important;
  background-color: #000;
  width: 100%;
  height: 100%;
  background: url(https://raw.githubusercontent.com/kossiitkgp/kwoc-2017/master/static/images/intro-background.jpg);
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

.hero {
  margin-bottom: 0;
  #hero {
    color: $primary-text-white;
    text-align: center;
    margin: 2rem 0 1rem;
  }

  .control {
    margin: 0;
  }

  .button {
    background-color: rgba($color: #000000, $alpha: 0.5);
    border: 0px solid #fff;
    padding: 20px 30px;
    border-radius: 5px;
    color: $primary-text-white;
    transition: all 0.5s ease-in-out;

    &:hover {
      background-color: #fff;
      color: #000;
      transform: scale(1.05);
    }

    &:visited {
      background-color: rgba($color: #000000, $alpha: 0.5);
      border: 0px solid #fff;
      color: $primary-text-white;
      transition: all 0.5s ease-in-out;
    }
  }
}

.hero-body .container {
  margin-bottom: 10rem;
}

.container p {
  font-family: Route159;
}
.container h1 {
  font-family: Vegur;
}
#tags {
  text-align: center;
  min-height: 50vh;
  background-image: linear-gradient(135deg, #58182d 0%, #130816 50%);

  margin-bottom: 0 !important;
  .container {
    padding-bottom: 20px;
    #dark {
      color: #000;
      background-color: #b57aff;
    }
    #light {
      color: #923bff;
      background-color: #fff;
    }
  }
}

#tags {
  .container {
    h1 {
      font-size: 40px;
      align-items: center;
      color: #fff;
    }
    max-height: 100vh;
  }
  .tag.is-medium {
    background: rgba(#fff, 0.1);
    border-radius: 15px;
    color: #eee;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .hero {
    .manual-btns {
      flex-direction: column;
      padding: 0 20%;
      .control:not(:last-child) {
        margin-right: 0 !important;
      }
    }
  }
}
