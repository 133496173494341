@import 'index.scss';

@import '~bulma/sass/elements/box.sass';

.box {
  height: 70vh;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 5%;
  padding: 2%;

  h2 {
    font-family: Vegur;
    text-align: center;
    font-size: 40px;
  }
  h3 {
    text-align: center;
  }
  h3,
  label {
    font-family: Route159;
  }
  input {
    margin-top: 0;
    border: 0.5px solid #b57aff;
  }
  a {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 5px;
    padding-top: 5px;
    background-color: #b57aff;
    color: #fff;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(195, 117, 231, 0.2),
        0 6px 20px 0 rgba(41, 10, 48, 0.19);
      color: #fff;
      // font-size: 22px;
    }
  }
}

.checkbox {
  margin: 1%;
  margin-top: -1%;
}

#fontello-icon {
  margin-top: 1%;
  margin-left: 2%;
}

.radio + .radio {
  margin-left: 0;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .box {
    margin-left: 5%;
    margin-right: 5%;
  }

  #vertical-in-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  #fontello-icon {
    margin-top: 1.5%;
  }
}
