@import 'index.scss';
.home {
  scroll-behavior: smooth;
}
.footer-btm {
  background-image: linear-gradient(135deg, #9545f7 0%, #000000 50%);
  margin: 0;
  padding: 0;

  footer {
    margin: 0rem 0;
    height: 50%;
    padding: 3rem 0;
    margin-bottom: -20px;

    .container {
      .column {
        text-align: center;
        // background-image: linear-gradient(135deg, #e4ccac 0%, #000000 50%);
      }
    }

    h1 {
      color: rgb(255, 255, 255);
      font-size: 1.5rem;
    }

    h2 {
      color: #fff;
      font-size: 1rem;
    }

    a {
      display: block;
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      line-height: 30px;
    }
    a:hover {
      color: #b67dfc;
      font-size: 17px;
      font-weight: 500;
    }
  }
}
