@import 'index.scss';

.about {
  background-image: linear-gradient(135deg, #9545f7 0%, #000000 50%);
  color: white;
  padding-bottom: 5rem;
  .container {
    h1 {
      font-size: 40px;
      align-items: center;
    }
    display: grid;

    place-items: center;
    p {
      font-size: 17px;
    }
  }
}

.stats {
  background-color: #170f1a;
  margin: 0;
  padding: 3rem 0;
  color: rgb(168, 166, 166);

  .container {
    h1 {
      color: #c395ff;
      text-shadow: 0px 10px 25px rgba($color: #c395ff, $alpha: 0.5);
      font-size: 40px;
      text-align: center;
      margin: 0;
      padding: 1rem 0;
    }
    .columns {
      padding: 2rem 0;
      .column {
        margin: 0 2rem 2rem;
        padding: 3rem 0 0;
        border-radius: 2rem;
        background-color: rgba($color: #000000, $alpha: 0.5);
        img {
          margin-bottom: 10px;
          width: 35%;
          height: 35%;
          filter: invert(1);
        }
        h3 {
          font-size: 2rem;
          margin: 0;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .stats .container .columns .column {
    padding: 2rem 0;
  }
}
