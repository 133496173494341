@import "index.scss";
#testimonial {
	background-image: linear-gradient(135deg, #9545f7 0%, #000000 50%);
	.container {
		.title {
			color: rgb(218, 217, 217);
		}
		.subtitle {
			color: rgb(228, 224, 224);
		}
	}
}
