// import using ~
// Import only what you need from Bulma
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/generic.sass";
@import "~bulma/sass/base/helpers.sass";
@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/image.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/layout/hero.sass";
@import "~bulma/sass/layout/section.sass";
@import "~bulma/sass/components/card.sass";
@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/elements/tag.sass";

// ref:https://blog.prototypr.io/designing-a-dark-mode-for-your-ios-app-the-ultimate-guide-6b043303b941
// colors for dark mode
// background
$primary-background-black: #000000; // pure black, used by apple
$primary-background-grey: #121212; // used by google
$secondary-background-grey: #3a3a3c;

// text
$primary-text-white: #ffffff; // titles and text
$secondary-text-light-grey: #ebebf5; // text below title and too much in length
$tertiary-text-grey: #767680; // text for placeholder

// other colors for sub sub titles
$blue-for-darkmode: #64d2ff;
$violet-for-darkmode: #c66df3;
$seperator-line: #545458;

* {
	text-rendering: optimizeLegibility;
	box-sizing: border-box;
}

html {
	margin: 0;
}

body {
	overflow: hidden;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
@font-face {
	font-family: "Route159";
	src: local("Route159"), url(../fonts/Route159-Regular.otf) format("opentype");
}
@font-face {
	font-family: "Vegur";

	src: local("Vegur"), url(../fonts/Vegur-Regular.otf) format("opentype");
}
.App {
	.hero {
		h1 {
			font-size: 5rem;
		}
	}
}
