// FONTS
@font-face {
  font-family: 'Route159';
  src: local('Route159'),
    url(../../fonts/Route159-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Vegur';

  src: local('Vegur'), url(../../fonts/Vegur-Regular.otf) format('opentype');
}

// VARIABLES
$dashboard-body-background: #35393b;
$dashboard-font: Route159, Vegur;
$background-color-dashboard: #26292b;
$header-color: #b57aff;

$project-stats-header: #8794a1;
$project-card-header: #c1d3e5;
$project-buttons: #3e4345, 100%;

$text-color: white;
// ---------

.dashboard-container {
  // background: url('../../../images/code.jpeg') $background-color-dashboard;
  // background-size: fill;
  // background-repeat: no-repeat;

  background: $dashboard-body-background;

  .dashboard {
    border: 10px dashed #5f6e7d22;
    border-radius: 30px;
    background-color: $background-color-dashboard;
    font-family: $dashboard-font;
    color: $text-color;

    font-weight: bold;

    max-width: 1200px;
    margin: auto;
    margin-bottom: 5rem;
    padding: 0 1rem;

    .navbar {
      background-color: #000;
    }

    p,
    h1 {
      padding: 0px;
      margin: 0px;
    }

    // Classes which are used by all components, so not nested
    .add-project-card {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      max-width: 1200px;
      // height: 200px;

      // margin: 1%;
      border: 5px dashed #5f6e7d;

      border-radius: 20px;
      padding: 1rem;

      display: flex;

      align-items: center;
      margin: 0 2% 0 2%;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .header-add-project-card {
        flex-grow: 1;
        flex-basis: 0;
        padding: 3% 0 3% 3%;
        p {
          font-size: 24px;
          @media (max-width: 767px) {
            text-align: center;
            font-size: 16px;
          }
        }
      }
      .add-project-button-div {
        flex-grow: 1;
        flex-basis: 0;

        .add-project-button:hover {
          cursor: pointer;
          transform: scale(1.05);
        }

        .add-project-button:active {
          background-color: linear-gradient(135deg, #9552ea 50%, #b57aff 100%);

          .text-add-project {
            color: black;
          }
          .plus-sign {
            color: black;
          }
        }

        .add-project-button {
          @media (max-width: 767px) {
            height: 50px;
            width: 200px;
            font-size: 20px;
            margin-bottom: 2%;
          }

          outline: none;
          height: 60px;
          width: 300px;
          transition: all 0.2s ease-in-out;
          font-size: 28px;

          display: flex;
          justify-content: space-evenly;
          padding: 0px;

          align-items: center;

          background: linear-gradient(135deg, #b57aff 50%, #9552ea 100%);
          box-shadow: 0px 25px 50px rgba(181, 122, 255, 0.33);
          border-radius: 10px;

          .plus-sign {
            padding-left: 2%;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            font-size: 58px;
            height: 70px;
            color: white;
            margin: 0px;
          }

          .text-add-project {
            font-weight: bold;
            color: white;
          }
        }
      }
    }

    .card-component {
      background: #272a2b;
      /* Neumorphic Shadow */

      box-shadow: -25px -25px 50px #2e2f32, 25px 25px 50px #1c1e23;
      border-radius: 30px;
    }

    .github-svg {
      padding-top: 20%;
    }

    .grow-card {
      transition: all 0.2s ease-in-out;
    }
    .grow-card:hover {
      transform: scale(1.04);
    }

    .title-dashboard {
      display: block;
      // padding-top:2%;
      margin-top: 2%;
      // align-items: center;
      text-align: center;
      // font-family: 'Roboto Slab', serif;
      // font-family: Vegur;
      font-size: 32px;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    // Above classes are used by all components, so not nested

    .intro-card {
      display: flex;
      margin-top: -3% !important;
      padding-top: 10%;

      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        .avatar {
          padding: 2rem 0;
        }
      }

      .avatar {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 30%;
        @media (max-width: 767px) {
          max-width: 100%;
        }
        flex-grow: 1;
        text-align: center;

        background: #26292a;
        box-shadow: inset 15px 15px 50px #3d3e42;
        border-radius: 2rem;

        .avatar-img {
          max-width: 150px;
          max-height: 150px;
          box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.5);

          border-radius: 25px;
        }

        .avatar-content {
          margin-top: 10%;
          margin-left: 20%;
          margin-right: 20%;

          font-size: 20px;
          // box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.75);
          p {
            padding: 0px;
            margin: 0px;
          }

          #mentor-name {
            font-weight: bold;
            font-size: 26px;
          }

          #mentor-username {
            font-size: 18px;
          }
        }
      }

      .mentor-stats {
        max-width: 70%;
        @media (max-width: 767px) {
          max-width: 100%;
        }

        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        background-color: $background-color-dashboard;

        .mentor-stats-header {
          h1 {
            padding: 0px;
            // taken from https://css-tricks.com/snippets/css/gradient-text/
            background: -webkit-linear-gradient(#b57aff 50%, #9552ea 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0;
          }

          p {
            font-size: 24px;
            color: #8794a1;
            font-weight: normal;
          }
          margin: 0px 0px 2rem;

          text-align: center;
          font-size: 2rem;
          max-height: 25%;
          z-index: 10;

          flex-grow: 1;

          padding: 1%;
          color: $header-color;
          font-family: $dashboard-font;

          @media (max-width: 600px) {
            font-size: 1rem;
            margin: 1rem 0;
          }
        }

        .mentor-stats-content {
          display: flex;
          justify-content: space-around;
          flex-grow: 1;
          padding-top: 4.5%;

          .font-mentor-header {
            font-size: 24px;
          }

          .font-mentor-stats {
            font-size: 30px;
            font-weight: 600;
          }

          .small-stats-font {
            font-size: 30px;
          }

          .mstats {
            p {
              padding: 0px;
              margin: 0px;
            }

            text-align: center;
            flex-direction: column;
            display: flex;
            justify-content: space-between;

            margin: 0 1.5% 1.5% 1.5%;
            max-width: 210px;
            max-height: 180px;

            min-width: 210px;
            max-height: 180px;

            flex-grow: 1;
            flex-basis: 0;

            padding: 1.5%;

            font-family: Inter;
            font-style: normal;

            color: $project-stats-header;
          }

          .purple-card {
            background: linear-gradient(135deg, #b57aff 50%, #9552ea 100%);
            color: $text-color;
          }

          .non-purple-card {
            .font-mentor-stats {
              color: $header-color;
            }
          }
        }

        @media screen and (max-width: 600px) {
          .font-mentor-stats {
            margin-top: -0.5rem !important;
          }
          .mentor-stats-content {
            display: flex;
            flex-direction: column;
            margin: auto;
            justify-content: center;

            .mstats {
              margin-top: 10px;
              padding: 2rem 0;
            }
          }
        }

        .badges {
          p,
          h1 {
            padding: 0px;
            margin: 0px;
          }
          flex-grow: 1;
          margin: 1.5%;
          padding: 1%;

          box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.75);
        }
      }
    }

    .projects {
      display: flex;
      flex-direction: column;
      margin: 3rem 0;

      .endEvals-guide {
        z-index: 10;
        p,
        h1 {
          padding: 0 1% 0 1%;
          margin: 0 1% 0 1%;
          text-align: center;
        }

        flex-grow: 1;

        padding: 0;
        font-family: $dashboard-font;
        font-size: 20px;
        margin: 0;

        // background: -webkit-linear-gradient(#eb8952 50%, #eb8952 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }

      .message {
        z-index: 10;
        p,
        h1 {
          padding: 0 1% 0 1%;
          margin: 0 1% 0 1%;
          text-align: center;
        }

        flex-grow: 1;

        padding: 1%;
        font-family: $dashboard-font;
        font-size: 30px;
        margin: 0 0 2rem 0;

        background: -webkit-linear-gradient(#eb8952 50%, #eb8952 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .project-header {
        z-index: 10;
        p,
        h1 {
          padding: 0 1% 0 1%;
          margin: 0 1% 0 1%;
          text-align: left;
        }

        flex-grow: 1;

        padding: 1%;
        font-family: $dashboard-font;
        font-size: 20px;
        margin: 0 0 2rem 0;
        // taken from https://css-tricks.com/snippets/css/gradient-text/
        background: -webkit-linear-gradient(#b57aff 50%, #9552ea 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .projectcard {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 1% 0 1%;

        .project-c {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-basis: 0.33;

          justify-content: space-around;
          margin: 2%;
          padding: 1%;
          margin: 0 auto 2% auto;

          border-radius: 10px;
          max-width: 350px;
          max-height: 200px;

          min-width: 350px;
          min-height: 120px;

          // height: 200px;
          // width: 400px;

          p,
          h1 {
            padding: 0px;
            margin: 0px;

            text-align: center;
          }

          .anchor-align {
            display: flex;
            flex-direction: row;

            align-items: center;

            .project-card-avatar {
              height: 40px;
              width: 40px;
              margin-top: 0rem;
              margin-right: 1rem;
              margin-left: 1.5rem;
              // padding-left: 1rem;

              border-radius: 50%;

              border: 2px solid $project-card-header;
            }

            p {
              color: $project-card-header;
              font-size: 36px;
              text-align: center;
              margin: 0px;
              padding-top: 1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .project-name {
              margin-top: 0;
              padding-top: 0;
              font-size: 36px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .project-buttons {
            display: flex;

            justify-content: space-around;
            padding-top: 5%;

            .project-button-small {
              height: 40px;
              background: #3e4345;
              border-radius: 8px;
              color: #bfb3c8;

              display: flex;
              text-align: center;

              // margin: 2%;
              margin-left: 2%;
              margin-right: 2%;

              padding-left: 5%;
              padding-right: 5%;
              // padding: 5%;
              font-size: 16px;
              align-items: center;
              cursor: pointer;

              img {
                filter: invert(100%);
                opacity: 0.75;
              }
            }

            .project-button-small:active {
              background-color: #a0a3f4;
            }

            .project-button-small:hover {
              color: #ad6fff;
              background: linear-gradient(#3e4345, rgb(44, 43, 43));
            }
          }
        }

        .add-project-card-small {
          max-width: 350px;
          max-height: 140px;

          min-width: 350px;
          min-height: 120px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          a {
            display: flex;
            align-items: center;
            color: #8794a1;
            h4 {
              text-align: center;
              font-size: 24px;
            }

            text {
              padding-left: 1%;
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 120px;

              color: #8794a1;
              margin: 5px;
              padding-bottom: 5px;
              align-items: center;
            }
          }

          a:hover {
            color: black;

            text {
              color: black;
            }
          }

          a:active {
            color: white;

            text {
              color: white;
            }
          }
        }

        @media screen and (max-width: 600px) {
          .project-c {
            margin: 10px 0 10px 0;
          }
        }
      }
    }

    .students {
      display: flex;
      flex-direction: column;
      margin: 3rem 0;
      .student-header {
        p,
        h1 {
          padding: 0 1% 0 1%;
          margin: 0 1% 0 1%;
          text-align: left;
        }

        flex-grow: 1;

        padding: 1%;

        font-family: $dashboard-font;
        font-size: 20px; // taken from https://css-tricks.com/snippets/css/gradient-text/
        background: -webkit-linear-gradient(#b57aff 50%, #9552ea 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .student-card {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;

        margin: 0 1% 0 1%;

        .student-c {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          flex-basis: 0.33;

          justify-content: space-around;
          margin: 2%;
          padding: 1%;

          margin: 0 auto 2% auto;
          border-radius: 10px;

          max-width: 350px;
          max-height: 200px;

          min-width: 350px;
          min-height: 120px;

          // height: 200px;
          // width: 400px;

          p,
          h1 {
            padding: 0px;
            margin: 0px;

            text-align: center;
          }

          .student-card-header {
            display: flex;
            flex-direction: row;

            align-items: center;

            .avatar-students-card {
              height: 60px;
              width: 60px;

              margin: 1rem;

              border-radius: 50%;

              border: 2px solid $project-card-header;
            }

            .student-name {
              font-size: 36px;
              margin-left: 2%;
              color: $project-card-header;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            @media screen and (min-width: 1200px) {
              .student-name {
                font-size: 36px;
              }
            }
          }

          .student-button {
            display: flex;
            justify-content: space-around;
            margin: 1%;
            padding: 1%;
            max-height: 45px;

            height: 20%;
            flex-grow: 1;
            display: flex;

            .student-profile {
              padding-top: 2%;
              padding-left: 5%;
              padding-right: 5%;
            }

            .student-button-small {
              height: 45px;

              background: #3e4345;
              border-radius: 8px;
              color: #bfb3c8;

              display: flex;
              text-align: center;

              // margin: 2%;
              margin-left: 2%;
              margin-right: 2%;

              padding-left: 5%;
              padding-right: 5%;
              // padding: 5%;
              font-size: 20px;
              align-items: center;
              cursor: pointer;
            }

            .fill-evals {
              pointer-events: none;
              background-color: #3e4345;
            }

            a:hover {
              color: black;
              background: linear-gradient(#3e4345, rgb(44, 43, 43));

              text {
                color: black;
              }
            }

            a:active {
              color: white;
              background-color: #a0a3f4;
              text {
                color: white;
              }
            }
          }
        }
      }

      @media screen and (max-width: 600px) {
        .student-card {
          margin: 10px 0 10px 0;
        }
      }
    }

    .resource-card {
      padding: 2% 3% 2% 3%;

      .resource-header {
        font-family: $dashboard-font;
        font-size: 26px; // taken from https://css-tricks.com/snippets/css/gradient-text/
        background: -webkit-linear-gradient(#b57aff 50%, #9552ea 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .avatar-resource {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin: 5% 0;
        &:hover {
          opacity: 0.5;
        }
      }

      .table {
        border-collapse: collapse;
        font-size: 20px;
        width: 100%;

        th {
          color: #5f6e7d;
          border: none;
          padding-top: 2%;
          padding-bottom: 2%;
        }
        td {
          border: none;
          color: #5f6e7d;
          align-items: center;

          .resource-link {
            margin: 2% 0 2% 0;
          }

          p {
            margin: 1% 0 1% 0;
          }

          a {
            color: #8794a1;
          }
        }
        td:nth-child(1) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4rem;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        td:nth-child(2) {
          width: 100%;
          padding-left: 0.5rem;
        }
        td {
          padding: 1rem 0;
        }
        td:nth-child(3) {
          color: #5f6e7d;
        }
        tr:hover {
          background: rgba(#a0a3f4, 0.2);
          td {
            border: none;
            a {
              color: rgb(255, 255, 255);
            }
          }
        }

        tr:active {
          td {
            color: white;
          }
        }
      }
    }

    .announcements {
      background: #26292a;
      box-shadow: inset 15px 15px 50px #3d3e42;
      padding: 1%;

      h1 {
        font-size: 32px; // taken from https://css-tricks.com/snippets/css/gradient-text/
        background: -webkit-linear-gradient(#b57aff 50%, #9552ea 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 10;
        margin: 1rem 1rem 2rem;
      }

      border-radius: 20px;
      margin: 2%;

      .anc-card {
        p,
        h1 {
          padding: 0px;
          margin: 0px;
        }
        background: linear-gradient(-125deg, #ffb67aaa, #ff730038 25%);
        h1 {
          font-size: 16px;
          color: #a3a3a3;
          background: none;
          -webkit-background-clip: inherit;

          -webkit-text-fill-color: inherit;
        }

        p {
          font-size: 24px;
          color: #d6d6d6;
        }

        margin: 1%;
        margin-bottom: 2%;
        padding: 1%;

        border-radius: 10px;

        @media screen and (max-width: 600px) {
          padding: 12px;
        }
      }
    }
  }

  // Styles only for the student dashboard
  &.student-dashboard-body {
    .dashboard {
      .mentor-stats {
        margin-bottom: -3rem;
        @media screen and (max-width: 600px) {
          margin-bottom: 0;
        }
      }
    }
  }
}

#indiv-stats-table {
  max-height: 100%;
  overflow-y: hidden;
}
