.stats {
  text-align: center;
  margin: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://raw.githubusercontent.com/kossiitkgp/kwoc-2017/master/static/images/intro-background.jpg);
  background-size: cover;
}

.table-container {
  max-height: 100vh;
  overflow-y: scroll;

  table {
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  th,
  td {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    a {
      color: #a2b5f7;
      font-weight: bold;
      &:hover {
        text-decoration: underline dotted #c66df3;
      }
    }
  }

  th {
    text-align: left;
  }

  thead {
    position: sticky;
    top: 1;
    z-index: 1;
    th {
      background-color: #859cff;
    }
  }

  tbody {
    margin-top: 30px;
    tr {
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    td {
      position: relative;
      &:hover {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: -9999px;
          bottom: -9999px;
          background-color: rgba(255, 255, 255, 0.2);
          z-index: -1;
        }
      }
    }
  }
}
